var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-recommendedIteright"},[_vm._l((_vm.browseall),function(item,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.browseall !== null),expression:"browseall !== null"}],key:index,staticClass:"recommended-item-img"},[_c('div',{staticClass:"recommended-item-img-top"},[(_vm.hide)?_c('div',{staticClass:"love",class:{ active: item.status },on:{"click":function($event){return _vm.tabImgColor(item.id, item.status)}}}):_vm._e(),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"thumb-container"},[_c('div',{staticClass:"thumb"},[_c('router-link',{attrs:{"target":"_blank","to":{
                path: '/productdetails',
                query: { productdetailsId: item.id }
              }}},[_c('img',{attrs:{"src":item.hostImage +
                    _vm.imgSize(990),"alt":""}})])],1)])]),(item.itemAuctionStatus === 3 && item.itemAuctionWayId === 7)?_c('div',{staticClass:"status-item",staticStyle:{"background":"#CDA156"}},[_vm._v(" 成交 ")]):_vm._e(),(item.itemAuctionStatus === 4 && item.itemAuctionWayId === 7)?_c('div',{staticClass:"status-item",staticStyle:{"background":"#BFC3C4","color":"#666666"}},[_vm._v(" 流拍 ")]):_vm._e(),(item.itemAuctionStatus === 5 && item.itemAuctionWayId === 7)?_c('div',{staticClass:"status-item",staticStyle:{"background":"#BFC3C4","color":"#666666"}},[_vm._v(" 撤拍 ")]):_vm._e(),(
          item.itemAuctionStatus === 2 &&
            item.itemAuctionWayId === 7 &&
            _vm.auctionHouseLoginT === false
        )?_c('router-link',{staticClass:"status-item",attrs:{"target":"_blank","to":{
          path: '/buyerlive',
          query: {
            auctionId: _vm.$route.query.auctiondetailsId,
            auctionHouseTitle: _vm.auctionHouseName,
            title: _vm.chineseTitleAuctionStr
          }
        }}},[_vm._v(" 直播中")]):_vm._e(),(
          item.itemAuctionStatus === 2 &&
            item.itemAuctionWayId === 7 &&
            _vm.accessShow === false &&
            _vm.auctionHouseLoginNO
        )?_c('router-link',{staticClass:"status-item",attrs:{"target":"_blank","to":{
          path: '/buyerlive',
          query: {
            auctionId: _vm.$route.query.auctiondetailsId,
            auctionHouseTitle: _vm.auctionHouseName,
            title: _vm.chineseTitleAuctionStr
          }
        }}},[_vm._v(" 直播中")]):_vm._e(),(
          item.itemAuctionStatus === 2 &&
            item.itemAuctionWayId === 7 &&
            _vm.accessShow === false &&
            _vm.auctionHouseLoginN &&
            item.whetherSelfAuctionHouse
        )?_c('router-link',{staticClass:"status-item",attrs:{"target":"_blank","to":{
          path: '/liveauctionhouse',
          query: {
            liveId: _vm.$route.query.auctiondetailsId,
            title: _vm.chineseTitleAuction
          }
        }}},[_vm._v(" 直播中")]):_vm._e(),(
          item.itemAuctionStatus === 2 &&
            item.itemAuctionWayId === 7 &&
            _vm.accessShow === false &&
            _vm.auctionHouseLoginN &&
            item.whetherSelfAuctionHouse === false
        )?_c('a',{staticClass:"status-item",attrs:{"href":"javascript:;"}},[_vm._v("直播中")]):_vm._e(),(item.itemAuctionStatus === 1 && item.itemAuctionWayId === 7)?_c('div',{staticClass:"status-item",staticStyle:{"background":"#CDA156"}},[_vm._v(" 待拍 ")]):_vm._e()],1),_c('div',{staticClass:"recommended-item-img-bottom",attrs:{"title":item.title}},[_c('router-link',{attrs:{"target":"_blank","to":{
          path: '/productdetails',
          query: { productdetailsId: item.id }
        }}},[_vm._v(" Lot "+_vm._s(item.lot)+"："+_vm._s(item.title)+" ")]),_c('p',{staticClass:"font-size-small"},[_vm._v(" 拍品估价："+_vm._s(item.assess)+" "+_vm._s(item.currency)+" ")]),(item.nowPrice === '' && item.itemAuctionStatus !== 3)?_c('p',{staticClass:"start-pirce"},[_vm._v(" 起拍价格："+_vm._s(item.initialPrice)+" "+_vm._s(item.currency)+" ")]):(item.nowPrice !== '' && item.itemAuctionStatus !== 3)?_c('p',{staticClass:"start-pirce"},[_vm._v(" 当前价格："+_vm._s(item.nowPrice)+" "+_vm._s(item.currency)+" ")]):(
          item.nowPrice !== '' &&
            item.itemAuctionStatus === 3 &&
            _vm.accessShow === false
        )?_c('p',{staticClass:"start-pirce"},[_vm._v(" 落槌价："+_vm._s(item.nowPrice)+" "+_vm._s(item.currency)+" ")]):(
          item.nowPrice === '' &&
            item.itemAuctionStatus === 3 &&
            _vm.accessShow === false
        )?_c('p',{staticClass:"start-pirce"},[_vm._v(" 落槌价："+_vm._s(item.nowPrice)+" "+_vm._s(item.currency)+" ")]):(_vm.accessShow && item.itemAuctionStatus === 3)?_c('p',{staticClass:"start-pirce"},[_vm._v(" 落槌价：请登录 ")]):_vm._e()],1)])}),(_vm.browseall === null)?_c('div',{staticClass:"emty-brow"},[_c('img',{attrs:{"src":require("../../../assets/images/22.png"),"alt":""}}),_c('span',[_vm._v("此分类没有任何产品")])]):_vm._e(),_c('Login',{ref:"login"})],2)}
var staticRenderFns = []

export { render, staticRenderFns }